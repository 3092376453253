require('slick-carousel');
import $ from 'jquery';

$('.js--testimonials-school').slick({
  dots: true,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 4000,
  dotsClass: 'js--testimonials-grid-dots',
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
});

$('.js--testimonials-force').slick({
  dots: true,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 4000,
  dotsClass: 'js--testimonials-grid-dots',
  slidesToShow: 1,
  slidesToScroll: 1
});

$('.js--homepage-highlights').slick({
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: true
});
