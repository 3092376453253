let scrollButton = document.getElementsByClassName('js--scroll-top')[0];

function smoothScroll() {
  rootElement.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

scrollButton.addEventListener('click', smoothScroll);

let rootElement = document.documentElement;

function scrollMonitor() {
  let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
  let scrollPercentage = (rootElement.scrollTop / scrollTotal);

  if (scrollPercentage > 0.10) {
    scrollButton.classList.add("primary-footer-top-link--show")
  } else {
    scrollButton.classList.remove("primary-footer-top-link--show")
  }
}

document.addEventListener('scroll', scrollMonitor);
